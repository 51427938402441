@import "~antd/dist/antd.css";
@import "~react-image-gallery/styles/css/image-gallery.css";

.logo {
  height: 55px;
  text-align: center;
  padding-top: 15px;
  font-size: 16px;
  font-weight: bold;
}

.article-title {
  color: #595959;
  font-weight: bold;
}
.article-row tr td {
  border: none;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.login-container {
  position: absolute;
  top: 80px;
  left: 50%;
  margin-left: -150px;
  background: "white";
  width: 300px;
}
.login-form {
  width: 300px;
  max-width: 300px;
  display: contents;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.password-container {
  position: absolute;
  top: 80px;
  left: 50%;
  margin-left: -200px;
  background: "white";
  width: 300px;
}

.custom .ant-collapse-content-box {
  padding: 0;
}

.custom-description .ant-descriptions-view {
  border: none;
}

.ant-advanced-search-form {
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 1px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.image-gallery-thumbnail-image {
}

.image-gallery-thumbnail {
  max-width: 50px;
  max-height: 50px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  outline: none;
  border: 1px solid #337ab7;
}
